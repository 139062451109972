(function() {
    'use strict';

    angular
        .module('abcQualityMatchApp').filter('documentFileEncodeFilename', function () {
        return function (name) {
            return  window.encodeURIComponent(name)
        };
    });

    angular
        .module('abcQualityMatchApp')
        .controller('DocumentTreeController', DocumentTreeController);

    DocumentTreeController.$inject = ['$scope', '$state', '$stateParams',
        'DocumentFolder', 'AlertService', '$translate',
        'DocumentTree', 'DocumentFile', '$uibModal',
        'DocumentTreeUtils'];

    function DocumentTreeController ($scope, $state, $stateParams,
        DocumentFolder, AlertService, $translate,
        DocumentTree, DocumentFile, $uibModal,
        DocumentTreeUtils) {
        var vm = this;

        vm.fsItems = [];
        vm.searchTerms = null;
        vm.searchMatchCount = 0;
        vm.rootFolders = [];
        vm.showDeleted = $stateParams.showDeleted;

        vm.filterBySearchTerms = function(searchTerms){
            vm.searchTerms = searchTerms;
            if (!vm.searchTerms){
                DocumentTreeUtils.setDefaultOpenStates(vm.rootFolders);
                return;
            }
            vm.rootFolders.forEach(function(folder){
                DocumentTreeUtils.closeFolder(folder, vm.searchTerms);
            });

            vm.rootFolders.forEach(function(folder){
                vm.searchMatchCount = DocumentTreeUtils.setFilterMatchFlags(folder, vm.searchTerms);
            });
        };

        loadTreeRoot();

        function loadTreeRoot() {
            var params = {};
            params.includeDeleted = vm.showDeleted;
            DocumentTree.getEditableFolderTree(params, onSuccess, onError);

            function onSuccess(rootFolders) {
                var openFolderIds = [];
                vm.fsItems.forEach(function(isItem){
                    if (isItem.rowType === 'folder' && isItem.open){
                        openFolderIds.push(isItem.id);
                    }
                });

                vm.rootFolders = rootFolders;
                vm.fsItems = DocumentTreeUtils.rootFoldersToFlatFsItemList(rootFolders, openFolderIds);

                if (openFolderIds.length === 0){
                    DocumentTreeUtils.setDefaultOpenStates(vm.rootFolders);
                }

                if (vm.searchTerms) {
                    vm.filterBySearchTerms(vm.searchTerms);
                }
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }
        vm.reload = function(){
            loadTreeRoot();
        };

        vm.openCreateNewFileDialog = function(folderId){

            var documentFile = {
                name: null,
                id: null,
                publicFile: false,
                folderId: folderId,
                state: 'DRAFT',
                maintainers:[]
            };


            $uibModal.open({
                templateUrl: 'app/document/document-tree/document-file/edit-dialog/document-file-edit-dialog.html',
                controller: 'DocumentFileEditDialogController',
                controllerAs: 'vm',
                size: 'lg',
                backdrop: 'static',
                resolve:{
                    documentFile: function (){return documentFile;},
                    useCase: function(){return 'newFile';}
                }
            }).result.then(function(result) {
                $state.reload();
            });
        };


        vm.openCreateNewFolderDialog = function(){

            var folder = {
                name: null,
                id: null,
                permissions: [],
                parentFolderId: null
            };

            $uibModal.open({
                templateUrl: 'app/document/document-tree/document-folder/document-folder-dialog.html',
                controller: 'DocumentFolderDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: folder
                    }
            }).result.then(function(result) {
                $state.reload();
            });

        };

        vm.openEditFolderDialog = function(folderId){

            $uibModal.open({
                templateUrl: 'app/document/document-tree/document-folder/document-folder-dialog.html',
                controller: 'DocumentFolderDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: ['DocumentFolder', function(DocumentFolder){return DocumentFolder.CRUD.get({id:folderId}).$promise; }]
                }
            }).result.then(function(result) {
                $state.reload();
            });

        };

        vm.undeleteFolder = function(folderId){
            DocumentFolder.CRUD.undelete({id:folderId}, {}, onSuccess, onError);

            function onSuccess() {
                loadTreeRoot();
            }
            function onError(err) {
                AlertService.error($translate.instant("abcQualityMatchApp.documentFolder.undelete.fail"));
            }
        };

        vm.undeleteFile = function(fileId){
            DocumentFile.undelete({id:fileId}, {}, onSuccess, onError);

            function onSuccess() {
                loadTreeRoot();
            }
            function onError(err) {
                AlertService.error($translate.instant("abcQualityMatchApp.documentFile.undelete.fail"));
            }
        };


        vm.deleteDocumentFolder = function(folderId) {
            DocumentFolder.CRUD.delete({id:folderId}, onSuccess, onError);

            function onSuccess() {
                console.log('deleted folder');
                loadTreeRoot();
            }
            function onError(err) {
                console.log("failed to delete folder " + err);
                AlertService.error($translate.instant("abcQualityMatchApp.documentFolder.delete.fail"));
            }
        };

        vm.deleteDocumentFile = function(fileId) {
            DocumentFile.delete({id:fileId}, onSuccess, onError);

            function onSuccess() {
                console.log('deleted file');
                loadTreeRoot();
            }

            function onError(err) {
                console.log("failed to delete file " + err);
            }
        };

        vm.folderClick = function(folder){
            folder.open = !folder.open;
            if (!folder.open){
                DocumentTreeUtils.closeFolder(folder);
            }
        };
    }
})();











