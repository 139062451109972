(function () {
    'use strict';

    angular
        .module('abcQualityMatchApp')
        .factory('DocumentTreeUtils', DocumentTreeUtils);

    function DocumentTreeUtils () {

        var service = {
            setFilterMatchFlags: setFilterMatchFlags,
            rootFoldersToFlatFsItemList: rootFoldersToFlatFsItemList,
            closeFolder: closeFolder,
            setDefaultOpenStates: setDefaultOpenStates
        };
        return service;

        function setFilterMatchFlags(folder, searchTerm){
            var matches = 0;

            folder.subFolders.forEach(function(sub){
                matches = matches + setFilterMatchFlags(sub, searchTerm);
            });

            folder.files.forEach(function(file){
                var fileMatch = isMatch(file.name, searchTerm) || isMatch(file.classification, searchTerm);
                file.filterMatch = fileMatch;
                if (fileMatch){
                    ++matches;
                }
            });

            if (isMatch(folder.name, searchTerm)){
               ++matches;
            }
            folder.filterMatch = matches > 0;
            return matches;
        }

        function isMatch(text, searchTerm){
            if (!text){
                return false;
            }
            return text.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
        }

        function closeFolder(folder){
            folder.open = false;

            folder.subFolders.forEach(function(sub){
                closeFolder(sub);
            });
        }

        function setDefaultOpenStates(rootFolders){
            rootFolders.forEach(function(folder){
                folder.open = true;
                folder.subFolders.forEach(function(sub){
                    closeFolder(sub);
                });
            });
        }

        function rootFoldersToFlatFsItemList(rootFolders, openFolderIds){
            var list = [];
            rootFolders.forEach(function(folder){
                toFlatList(folder, 0, openFolderIds).forEach(function(sub){list.push(sub);});
            });
            return list;
        }

        function toFlatList(folder, nestingLevel, openFolderIds){
            var list = [];
            folder.nestingLevel = nestingLevel;
            folder.open = openFolderIds.indexOf(folder.id) !== -1;
            folder.rowType = 'folder';
            folder.trackingId = 'folder' + folder.id;
            list.push(folder);

            folder.subFolders.forEach(function(sub){
                sub.parentFolder = folder;
                var subList = toFlatList(sub, nestingLevel + 1, openFolderIds);
                subList.forEach(function(subTree){
                    list.push(subTree);
                });
            });

            folder.files.forEach(function(file){
                file.rowType = 'file';
                file.parentFolder = folder;
                file.trackingId = 'file' + file.id;
                list.push(file);
            });
            return list;
        }
    }
})();
