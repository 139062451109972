(function () {
    'use strict';
    // DO NOT EDIT THIS FILE, EDIT THE GULP TASK NGCONSTANT SETTINGS INSTEAD WHICH GENERATES THIS FILE
    angular
        .module('abcQualityMatchApp')
        .constant('VERSION', "2022-11-14")
        .constant('DEBUG_INFO_ENABLED', false)
        .constant('ENV', "prod")
;
})();
